export const TempusData = () => ({
  init() {
    if (this.user === undefined) {
      this.user = {}
    }
  },
  atlassianUrl: "https://medienagenten.atlassian.net/",
  // proxyUrl: "https://tempus.ddev.site/index.php",
  proxyUrl: "./index.php",
  viewEachWorklog: false,
  fetchNotChargedOnly: false,
  fetchDoneOnly: false,
  trackingStatusActive: "Tracking läuft",
  trackingStatusOff: "Tracking starten",
  trackingStatusError: "Something went wrong, still tracking",
  showPanel: "dashboard",

  // to be overwritten
  nextElementId: null,

  // some timers and status to see, if we have to update
  // data
  // lastUpdated: Alpine.$persist({}),

  // worklog active tracked
  activeWorklog: Alpine.$persist(null),
  activeWorklogStart: Alpine.$persist(0),
  activeWorklogSeconds: Alpine.$persist(0),
  trackingStatus: Alpine.$persist("Tracking starten"),
  activeWorklogTimeForDisplay: Alpine.$persist(""),

  lastWorklogs: [],
  personalIssuesTimesheet: [],
  personalWorklogsTimesheet: [],

  callbackWorklogChanges: [],

  issueSelected: {}, //
  issueSelectedData: Alpine.$persist({}),
  projectSelected: Alpine.$persist({ id: null }),

  // timesheet
  projectSelectedTimesheet: Alpine.$persist({}),
  projectIssuesTimesheet: [],
  projectWorklogsTimesheet: [],
  projectWorklogsTimesheetTmp: [],
  // all projects as timesheets currently fetched
  timesheets: Alpine.$persist({}),
  timesheetkeys: [],
  timesheetDateFrom: "",
  timesheetDateTo: "",
  toggleCheckboxesForCharge: true,

  // customerOptions
  customerContextFieldForJql: "10068",
  customerCustomfield: "customfield_10068",
  customerContext: "10215",
  customerOptions: {},
  customerSelectedForTimesheet: Alpine.$persist({}),

  // User
  // worklog time tracked today
  secondsSpentToday: Alpine.$persist(0),
  formattedSecondsToday: Alpine.$persist(""),

  // all issues currently fetched
  issues: {}, //Alpine.$persist({}), AXEL
  // lists of keys of projects and issues and mayby sometime more types,
  // not to be persisted, mainly used in comboboxes
  itemlists: [],
  itemlistsActive: [],

  // all projects currently fetched, peristence in question
  projects: Alpine.$persist({}),
  projectkeys: [],

  showLogin: false,

  // user object
  users: Alpine.$persist({}),
  userSelectedForTimesheet: Alpine.$persist({}),
  user: Alpine.$persist({}),

  // template to set and update worklog data
  issueSelectedDataTemplate: {
    id: null,
    key: null,
    fields: {
      issuetype: {
        name: null,
      },
      timespent: null,
      priority: {
        name: null,
      },
      labels: [],
      summary: null,
      worklog: {
        worklogs: [],
      },
    },
  },

  // tools to fetch data from jira
  async fetchGETSomething(endpoint = "", query = "") {
    const response = await fetch(
      this.proxyUrl + "?endpoint=" + endpoint + query,
      {
        headers: { "Content-Type": "application/json" },
      },
    )
    return await response.json()
  },

  async fetchStaticUrl(url) {
    const response = await fetch(this.proxyUrl + "?static_fetch=" + url, {
      headers: { "Content-Type": "application/json" },
    })
    return await response.json()
  },

  async fetchPOSTPUTSomething(method = "POST", endpoint = "", json) {
    json["endpoint"] = endpoint
    json["method"] = method
    const response = await fetch(this.proxyUrl, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(json),
    })
    return await response.json()
  },

  async fetchDELSomething(endpoint = "") {
    const response = await fetch(
      this.proxyUrl + "?endpoint=" + endpoint + "&method=DELETE",
      {
        headers: { "Content-Type": "application/json" },
      },
    )
    return await response.json()
  },
  getProjectIssuesTimesheet() {
    return this.projectIssuesTimesheet
  },

  // tools to view data
  formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.round((seconds % 3600) / 60)
    return hours + "h " + minutes + "m"
  },

  formatTimeSpendFromInput(timeValue) {
    const timeHM = timeValue.split(":")
    return timeHM[0] + "h " + timeHM[1] + "m"
  },

  formatDateTime(timestring) {
    // bad workaround for now: strip millisectonds and add
    // hardcoded 2 hours localtime from utc
    // TODO: use actual timezone shift instead of 0200
    const time =
      this.toIsoString(new Date(timestring)).slice(0, 19) + ".000+0200"
    return time
  },

  formatDate(dateString) {
    const newDate = new Date(dateString)
    return newDate.toLocaleString("de-DE")
  },

  formatDateOnly(dateString) {
    const newDate = new Date(dateString)
    // must be de-DE to work correctly.
    return newDate.toLocaleString("de-DE").split(",")[0]
  },

  formatTimeOnly(dateString) {
    const newDate = new Date(dateString)
    // must be de-DE to work correctly.
    return newDate.toLocaleString("de-DE").split(",")[1]
  },

  getToday() {
    const today = new Date()
    const days = {
      1: "Montag",
      2: "Dienstag",
      3: "Mittwoch",
      4: "Donnerstag",
      5: "Freitag",
      6: "Samstag",
      0: "Sonntag",
    }
    console.log(today.getDay())
    return days[today.getDay()] + ", " + today.toLocaleDateString()
  },

  getWorklogComment(worklog) {
    return worklog.comment ? worklog.comment.content[0].content[0].text : "-"
  },

  formatWorklogTimeForForm(worklog) {
    const hours = parseInt(worklog.timeSpentSeconds / 3600)
    const minutes = parseInt((worklog.timeSpentSeconds % 3600) / 60)
    return (
      ((hours.toString().length < 2 && "0" + hours.toString()) || hours) +
      ":" +
      ((minutes.toString().length < 2 && "0" + minutes.toString()) || minutes)
    )
  },

  getTodayForForm(offset = 0) {
    const today = new Date()
    today.setDate(today.getDate() + offset)
    return this.formatDateForForm(this.toIsoString(today))
  },

  /**
   * found on stackoverflow
   * get correct iso string for local timezone
   */
  toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num
      }

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    )
  },

  getFirstDayOfMonth() {
    const newday = new Date()
    const firstofmonth = new Date(newday.getFullYear(), newday.getMonth(), 1)
    return this.formatDateForForm(this.toIsoString(firstofmonth))
  },

  formatDateForForm(dateTimeString) {
    return dateTimeString.slice(0, 10)
  },

  getNowForForm() {
    const today = this.toIsoString(new Date())
    return this.formatDateTimeForForm(today)
  },

  formatDateTimeForForm(dateTimeString) {
    return dateTimeString.slice(0, 16)
  },

  calculateWorklogSummary(worklogs) {
    let timeSpentSeconds = 0
    worklogs.forEach((worklog) => {
      timeSpentSeconds += worklog.timeSpentSeconds
    })
    const hours = parseInt(timeSpentSeconds / 3600)
    const minutes = parseInt((timeSpentSeconds % 3600) / 60)
    return (
      hours +
      "h&nbsp;" +
      ((minutes.toString().length < 2 && "0" + minutes.toString()) || minutes) +
      "m"
    )
  },

  calculateIssuesWorklogSummary(issues) {
    let timeSpentSeconds = 0
    issues.forEach((issue) => {
      const worklogs = issue.worklogs
      worklogs.forEach((worklog) => {
        timeSpentSeconds += worklog.timeSpentSeconds
      })
    })
    const hours = parseInt(timeSpentSeconds / 3600)
    const minutes = parseInt((timeSpentSeconds % 3600) / 60)
    return (
      hours +
      "h&nbsp;" +
      ((minutes.toString().length < 2 && "0" + minutes.toString()) || minutes) +
      "m"
    )
  },

  toggleViewEachWorklog() {
    this.viewEachWorklog = !this.viewEachWorklog
  },

  toggleFetchNotChargedOnly() {
    this.fetchNotChargedOnly = !this.fetchNotChargedOnly
  },

  toggleFetchDoneOnly() {
    this.fetchDoneOnly = !this.fetchDoneOnly
  },
})
